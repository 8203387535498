<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes" v-if="serviceId === '' "/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center my-4" v-if="!LOCK_SERVICE">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo Registro
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`" ref="modal-form" size="lg">
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model ref="rule-Form"
          :model="form" :rules="rules"
          layout="vertical" :wrapper-col="{ span: 24}">
          <!-- Fuente de la Incidencia-->
          <a-form-model-item label="Fuente de Incidente" >
            <a-select v-model="incidentableType" :disabled="id !== '0' "
                      placeholder="Fuente de Incidente"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in incidentableTypes" :key="option.value" :value="option.value" >
                {{ option.text}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- Fuente de la Incidencia-->
          <template v-if="incidentableType">
            <!-- ServicePlanning Select-->
            <a-form-model-item v-if="incidentableType === 'servicePlannings' " ref="incidentable" label="Servicio" prop="incidentable" >
              <a-select v-model="form.incidentable" :disabled="id !== '0' "
                        placeholder="Seleccione el Servicio"
                        show-search :filter-option="filterOption">
                <a-select-option  v-for="option in service_plannings" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{ option.sap_programming_code }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- ServicePlanning Select-->
            <!-- ServicePlanningsCarrier Select-->
            <a-form-model-item v-else-if="incidentableType === 'servicePlanningsCarriers' " ref="incidentable" label="Servicio de Transporte" prop="incidentable" >
              <a-select v-model="form.incidentable" :disabled="id !== '0' "
                        placeholder="Seleccione el Servicio de Transporte"
                        show-search
                        :filter-option="filterOption">
                <a-select-option  v-for="option in service_plannings_carriers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{ option.code }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- ServicePlanningsCarrier Select-->
            <!-- DischargeMaterial Select-->
            <a-form-model-item v-else-if="incidentableType === 'dischargeMaterials' " ref="incidentable" label="Residuo en Descarga" prop="incidentable" >
              <a-select v-model="form.incidentable" :disabled="id !== '0' "
                        placeholder="Seleccione el Residuo en Descarga"
                        show-search :filter-option="filterOption">
                <a-select-option  v-for="option in discharge_materials" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{ option.code }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- DischargeMaterial Select-->
            <!-- Fecha de Registro-->
            <a-form-model-item ref="register_at" label="Fecha de Registro" prop="register_at" >
              <a-date-picker  v-model="form.register_at" format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
            </a-form-model-item>
            <!-- Fecha de Registro-->
            <!-- Fecha de Ejecución-->
            <a-form-model-item ref="execution_at" label="Fecha de Ejecución" prop="execution_at" >
              <a-date-picker  v-model="form.execution_at" format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
            </a-form-model-item>
            <!-- Fecha de Ejecución-->
            <!-- Fecha de Responsable de la Incidencia-->
            <a-form-model-item ref="user_id" label="Responsable de la incidencia" prop="user_id" >
              <a-select v-model="form.user_id" :disabled="id !== '0' "
                        placeholder="Seleccionar el Responsable de la incidencia"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option  v-for="option in users" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- Fecha de Responsable de la Incidencia-->
            <!-- Turno-->
            <a-form-model-item ref="shift_id" label="Turno" prop="shift_id" >
              <a-select v-model="form.shift_id" :disabled="id !== '0' "
                        placeholder="Seleccionar el Turno"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option  v-for="option in shifts" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- Turno-->
            <!-- Área -->
            <a-form-model-item label="Área" v-if="id === '0'">
              <a-select v-model="area"
                        placeholder="Área"
                        show-search :filter-option="filterOption">
                <a-select-option  v-for="option in areas" :key="option.jsonApi.id" :value="option.jsonApi.id">
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- Área -->
            <!-- Tipo de Incidencia-->
            <a-form-model-item ref="incident_type_id" label="Tipo de Incidencia" prop="incident_type_id" >
              <a-select v-model="form.incident_type_id" :disabled="id !== '0' "
                        placeholder="Seleccionar el Tipo de Incidencia"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option  v-for="option in incident_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- Tipo de Incidencia-->
            <a-form-model-item ref="incident_status" label="Estatus de Incidencia" prop="incident_status" >
              <a-select v-model="form.incident_status"
                        placeholder="Seleccionar un registro"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option  v-for="option in incident_statuses" :key="option" :value="option" >
                  {{option}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- Detalle de la Incidencia-->
            <a-form-model-item ref="incidentDetail" label="Detalle de Incidencia" prop="incidentDetail" >
              <a-textarea v-model.trim="form.incident_detail" :rows="5"/>
            </a-form-model-item>
            <!-- Detalle de la Incidencia-->
            <!-- Botones-->
            <div class="row justify-content-center" v-show="!load">
              <responsive-button variant="primary" pill size="sm" v-if="!LOCK_SERVICE"
                                 text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
              <!--            <responsive-button v-if="id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>-->
            </div>
            <!-- Botones-->
          </template>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-incident-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"/>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailIncidentTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { mapGetters } from 'vuex'
// import { generalServiceFormMixin } from '@/mixins/serviceFormMixins/generalServiceFormMixin'
export default {
  name: 'incidentForm',
  components: {
    DetailIncidentTable,
    DetailCatalogBar,
  },
  props: {
    serviceId: {
      default: '',
      type: [String],
      required: false,
    },
  },
  mixins: [
    // generalServiceFormMixin,
    getGeneralMixin,
  ],
  data() {
    return {
      // <!-- Constantes -->
      // collectorOptions: [
      //   { item: true, name: 'SI' },
      //   { item: false, name: 'NO' },
      // ],
      resource: 'incidents',
      resourceName: 'Incidencias del Servicio',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Incidencias del Servicio',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'code',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave del Incidente' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      incidentableType: '',
      area: '',
      form: {
        incidentable: undefined,
        register_at: '',
        execution_at: '',
        user_id: undefined,
        shift_id: undefined,
        incident_type_id: undefined,
        incident_rejected: '',
        incident_status: '',
        incident_detail: '',
      },
      // Relaciones
      relationships: {
        incidentable: {
          data: { id: 0, type: 'servicePlannings' },
        },
        users: {
          data: { id: 0, type: 'users' },
        },
        shifts: {
          data: { id: 0, type: 'shifts' },
        },
        incidentTypes: {
          data: { id: 0, type: 'incidentTypes' },
        },
      },
      // arrays selects
      service_plannings: [],
      service_plannings_carriers: [],
      discharge_materials: [],
      work_centers: [],
      users: [],
      shifts: [],
      incident_types: [],
      incident_statuses: ['Abierto', 'Cerrado'],
      areas: [],
      // Reglas de validación
      rules: {
        // execution_at: [
        //   { required: true, message: 'El campo Nombre de Cliente es obligatorio.', trigger: 'blur' },
        //   { min: 2, max: 100, message: 'El campo Nombre debe contener entre 2 y 100 caracteres.', trigger: 'blur' },
        //   { type: 'string', message: 'El campo Nombre debe ser de tipo texto.', trigger: 'blur' },
        // ],
        incidentableType: [
          { required: true, message: 'El campo Fuente de Incidente es obligatorio.', trigger: 'blur' },
        ],
        incidentable: [
          { required: true, message: 'El campo Servicio es obligatorio.', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: 'El campo Usuario es obligatorio.', trigger: 'blur' },
        ],
        shift_id: [
          { required: true, message: 'El campo Turno es obligatorio.', trigger: 'blur' },
        ],
        incident_type_id: [
          { required: true, message: 'El campo Fecha Registro es obligatorio.', trigger: 'blur' },
        ],
        register_at: [
          { required: true, message: 'El campo Tipo de Incidencia es obligatorio.', trigger: 'blur' },
        ],
        incident_rejected: [
          { required: true, message: 'El campo Aplica Rechazo es obligatorio.', trigger: 'blur' },
          { min: 2, max: 5, message: 'El campo Aplica Rechazo debe contener entre 2 y 5 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Aplica Rechazo debe ser de tipo texto.', trigger: 'blur' },
        ],
        // incident_status_id: [
        //   { required: true, message: 'El campo Estatus de Incidencia es obligatorio.', trigger: 'blur' },
        // ],
        incident_status: [
          { required: true, message: 'El campo Estatus de Incidencia es obligatorio.', trigger: 'blur' },
        ],
        incident_detail: [
          { required: true, message: 'El campo Detalle de Incidencia es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Detalle de Incidencia debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Detalle de Incidencia debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  watch: {
    incidentableType(newValue) {
      if (newValue === 'servicePlannings') {
        this.getService_plannings()
      } else if (newValue === 'servicePlanningsCarriers') {
        this.getService_plannings_carriers()
      } else if (newValue === 'dischargeMaterials') {
        this.getDischarge_materials()
      }
    },
    area(newValue) {
      this.getIncident_types(newValue)
    },
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
        'page[size]': `${this.perPageValue}`,
        sort: `${this.sortDirection}${this.sortValue}`,
        include: 'incidentable,users,shifts,incidentTypes',
      }
      if (this.searchValue !== '') params['filter[global]'] = this.searchValue
      if (this.serviceId !== '') params['filter[service]'] = this.serviceId

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    async getService_plannings() {
      let params = {}
      if (this.serviceId === '') {
        params = {
          sort: 'programming_code',
        }
      } else {
        params = {
          'filter[programmingCode]': this.serviceId,
        }
      }
      await this.getResourceData(params, 'servicePlannings')
      this.service_plannings = this.generalResponse
    },
    async getService_plannings_carriers() {
      let params = {}
      if (this.serviceId === '') {
        params = {
          sort: 'code',
        }
      } else {
        params = {
          'filter[servicePlannings]': this.serviceId,
        }
      }
      await this.getResourceData(params, 'servicePlanningsCarriers')
      this.service_plannings_carriers = this.generalResponse
    },
    async getDischarge_materials() {
      let params = {}
      if (this.serviceId === '') {
        params = {
          sort: 'code',
        }
      } else {
        params = {
          'filter[servicePlannings]': this.serviceId,
        }
      }
      await this.getResourceData(params, 'dischargeMaterials')
      this.discharge_materials = this.generalResponse
    },
    async getUsers() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'users')
      this.users = this.generalResponse
    },
    async getShifts() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'shifts')
      this.shifts = this.generalResponse
    },
    async getAreas() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'areas')
      this.areas = this.generalResponse
    },
    async getIncident_types(area = '') {
      let params = {
        sort: 'name',
      }
      if (area !== '') {
        params = {
          sort: 'name',
          'filter[areas]': area,
        }
      }
      await this.getResourceData(params, 'incidentTypes')
      this.incident_types = this.generalResponse
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.getUsers()
      this.getShifts()
      this.getIncident_types()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.getAreas()
        this.id = '0'
        this.form.incidentable = undefined
        this.form.register_at = ''
        this.form.execution_at = ''
        this.form.user_id = undefined
        this.form.shift_id = undefined
        this.form.incident_type_id = undefined
        this.form.incident_status = ''
        this.form.incident_detail = ''
      } else {
        this.getIncident_types()
        // Update case
        this.id = data.jsonApi.id
        this.form.register_at = data.register_at
        this.form.execution_at = data.execution_at
        this.form.incident_detail = data.incident_detail
        this.form.user_id = data.users.jsonApi.id
        this.form.shift_id = data.shifts.jsonApi.id
        this.form.incident_type_id = data.incidentTypes.jsonApi.id
        this.form.incident_status = data.incident_status
        this.incidentableType = data.incidentable.jsonApi.type
        this.form.incidentable = data.incidentable.jsonApi.id
        if (this.incidentableType === 'servicePlannings') {
          this.getService_plannings()
        } else if (this.incidentableType === 'servicePlanningsCarriers') {
          this.getService_plannings_carriers()
        } else if (this.incidentableType === 'dischargeMaterials') {
          this.getDischarge_materials()
        }
      }
      this.$refs['modal-form'].show()
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.incidentableType = ''
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          // build the relationships with the form data
          const relationships = {
            incidentable: {
              data: { id: this.form.incidentable, type: this.incidentableType },
            },
            users: {
              data: { id: this.form.user_id, type: 'users' },
            },
            shifts: {
              data: { id: this.form.shift_id, type: 'shifts' },
            },
            incidentTypes: {
              data: { id: this.form.incident_type_id, type: 'incidentTypes' },
            },
          }
          // build the relationships with the form data
          // Store Object
          let action = 'post'
          const formObject = {
            register_at: `${this.form.register_at}`,
            execution_at: `${this.form.execution_at}`,
            incident_detail: `${this.form.incident_detail}`,
            incident_status: this.form.incident_status,
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }

          if (this.id !== '0') {
            // Update Object
            action = 'patch'
            formObject.jsonApi.id = this.id
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch(() => {
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch(() => {
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
